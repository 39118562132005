// Customizable Area Start
import React from "react";

import DocusignIntegrationController, {
  Props,
} from "./DocusignIntegrationController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import RequestModal from "./../../dashboard/src/BookNotaryRequest.web";
import Loader from "../../../components/src/Loader.web";
import { Box, IconButton, Typography, Button, styled } from "@material-ui/core";
import {
  Menu,
  Close,
  ArrowBackIosRounded,
  AddRounded,
} from "@material-ui/icons";
import { localProfile, bellIcon } from "./assets";
import MiniHeader from "../../dashboard/src/MiniHeader.web";
import Draggable from 'react-draggable'; 
import Cfzoomintegration92 from "../../../../packages/blocks/cfzoomintegration92/src/Cfzoomintegration92";

export default class DocusignIntegration extends DocusignIntegrationController {
  constructor(props: Props) {
    super(props);
  }

 

  render() {
    return (
      <>
        <Loader loading={this.state.loader} />
        <RequestModal
          data-testID="modalOpen"
          navigation={undefined}
          id={""}
          closeModal={this.closeBookNotaryRequestModal}
          isOpen={this.state.modalOpen}
          allRequestAPI={() => { } }
          serviceData={this.state.serviceData}
          cancelReqModal={this.state.cancelBookNowReqModal}
          yesButtonClick={this.bookNowYesButtonClick}
          noButtonClick={this.bookNowNoButtonClick}
          setModal={this.setBookNowModal}
          setLoader={this.setLoader}
          isNewRequestOrEditRequestOrInviteClient={"new"}
          backToEditRequest={() => { } }
          editRequest={undefined}        />
        <Box data-testID="test1"  className="test1">
          
          <MainBox
            height={"100vh"}
            overflow={"auto"}
            width="100%"
            justifyContent="space-between"
             data-testID="test2"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            
            <MainContentBox mt={"32px"} mx={"27px"}>
              <style>
                {`#docusign-iframe: {
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }`}
              </style>
              {this.state.sender_url !== "" && (
                <iframe
                  style={{ width: "75%", height: "100%", overflow: "hidden" }}
                  id="docusign-iframe"
                  src={this.state.sender_url}
                ></iframe>
              )}

               <Box display={"flex"} justifyContent={"end"}>

                  {!this.state.zoomModal && (<Button
                    variant="contained"
                    className="tabInsiderButton"
                    data-testID="joinMeetingButton"
                    onClick={() => this.setState({zoomModal:true})}
                  >
                    <Typography className="tabInsiderButtonTypography">
                      Join Meeting
                    </Typography>
                  </Button>)}

                {this.state.zoomModal && (
                  <Draggable>
                    <div className="zoomMettingSection">                  
                      <Cfzoomintegration92 navigation={undefined} id={""}> </Cfzoomintegration92>
                    </div>
                  </Draggable>
                )}
                </Box>
            </MainContentBox>
          </MainBox>
        </Box>
      </>
    );
  }
}

const MainBox = styled(Box)({
  "@media (min-width: 1025px)": {
    width:"100%",
    justifyContent:"space-between"
  },
});

const MainContentBox = styled(Box)({
  height: "calc(100vh - 144px)",
  "@media (min-width: 1025px)": {
    display: "flex",
    justifyContent:"space-between"
  },

  "& .zoomMettingSection": {
    width: '25%',
    height: '100%',
    position: 'fixed',
    bottom: '10px',
    right:"0",
    backgroundColor: 'white',
    border: '1px solid black',
    zIndex: 1000
  },

  "& .tabInsiderButton":{
    backgroundColor: "#012275",
    borderRadius: "4px",
    padding: 0,
    height: "44px",
    width: "324px",
    textTransform: "none",
  },
  "& .tabInsiderButtonTypography":{
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#FFF",
  }
});

const DesktopDrawerBox = styled(Box)({
  "@media (max-width: 1024px)": {
    display: "none",
  },
  display: "flex",
});

const MobileTabletDrawerBox = styled(Box)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StyledIconButton = styled(IconButton)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StatusBox = styled(Box)({
  "& .inprogress": {
    background: "#FEF3C7",
    color: "#D97706",
  },
  "& .statusBox": {
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 12px",
  },
});
// Customizable Area End
// Customizable Area End
